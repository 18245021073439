import Splide from '@splidejs/splide';
//DOM loaded
document.addEventListener("DOMContentLoaded", () => {
    //Slider init
    let slider = document.querySelectorAll('.pdw-slider');
    if (slider.length) {
        let conf = {
            type: 'loop',
            perPage: 1,
            perMove: 1,
            pagination: false,
            mediaQuery: 'min',
            breakpoints: {
                1024: {
                    perPage: 2,
                    padding: {
                        left: 120,
                        right: 120,
                    },
                }
            },
            gap: 30,
            autoplay: 5000,
            pauseOnHover: true
        }

        function updatePagination(splide, pagination) {
            const currentSlide = splide.index + 1;
            const totalSlides = splide.length;
            pagination.innerHTML = `<span class="text-primary fs-1">${currentSlide}</span> <span class="text-primary">/</span> <span class="text-primary">${totalSlides}</span>`;
        }

        const splideSliders = [];
        slider.forEach(item => {
            const splide = new Splide(item, conf).mount();
            splideSliders.push(splide);

            // Crée une div pour afficher la pagination
            const pagination = item.querySelector('.splide__pagination');
            if (pagination) {
                updatePagination(splide, pagination);

                // Met à jour la pagination à chaque mouvement du slider
                splide.on('move', () => {
                    updatePagination(splide, pagination);
                });
            }
        });

        //Observe vc row resize
        const row = document.querySelector('div[data-vc-full-width="true"]');
        if (row) {
            const observer = new MutationObserver((event) => {
                event.forEach((mr) => {
                    if (mr.attributeName === 'data-vc-full-width-init') {
                        splideSliders.forEach(splideSlider => {
                            splideSlider.mount();
                        });
                    }
                });
            });
            const config = {
                attributes: true,
                childList: false,
                subtree: false,
            };
            observer.observe(row, config);
        }
    }
});